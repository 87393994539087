/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { authUser } from "../API/User";

const ProtectedRoute = ({ children }) => {
  const [cookies, , removeCookie] = useCookies(["gam_token"]);
  const navigate = useNavigate();

  async function authenticateToken(cookie) {
    const parts = cookie?.split(" ")[1]?.split(".") ?? [];
    const token = parts.slice(0, 3).join(".");

    const res = await authUser(token);
    if (!res.msg.loggedin) {
      removeCookie("gam_token");
      navigate("/login");
    }
  }

  useEffect(() => {
    const token = cookies.gam_token;
    authenticateToken(token);

    // if (!token) navigate("/login");
  }, [cookies, navigate]);

  // If the cookie is present, render the protected route
  return children;
};

export default ProtectedRoute;
