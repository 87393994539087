import { createSlice } from "@reduxjs/toolkit";

export const ipDataSlice = createSlice({
  name: "ipData",
  initialState: { ip: {} },
  reducers: {
    updateIpData: (state, action) => {
      state.ip = action.payload;
    },
  },
});

export const { updateIpData } = ipDataSlice.actions;
export default ipDataSlice.reducer;
