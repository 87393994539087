/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { LogoutOutlined } from "@ant-design/icons";
import axios from "axios";
import {
  Select,
  Switch,
  DatePicker,
  Space,
  Typography,
  Button,
  Tag,
  Table,
} from "antd";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import dayjs from "dayjs";
import {
  AreaChart,
  ResponsiveContainer,
  Area,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import "../index.css";
import LoaderComp from "../Utility/LoaderComp";
import { useCookies } from "react-cookie";
import { destroyToken, fetchGamData } from "../API/User";
import Logo from "../helpers/Logo";
import selectCountryOptions from "../helpers/selectCountryOptions";

const { RangePicker } = DatePicker;
const { Text } = Typography;

const selectDeviceOptions = [
  { label: "All Devices", value: "All Devices" },
  { label: "Desktop", value: "Desktop" },
  { label: "Tablet", value: "Tablet" },
  { label: "Smartphone", value: "Smartphone" },
  { label: "Connected TV", value: "Connected TV" },
];

const createDate = (today) => {
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  return [dd, mm, yyyy];
};

function Dashboard(props) {
  const [userName, setUserName] = useState("");
  const [cookies, , removeCookie] = useCookies(["gam_token"]);
  const [token, setToken] = useState("");
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [searchDate, setSearchDate] = useState(0);

  const navigate = useNavigate();

  const [tag1, setTag1] = useState("");
  const [tag2, setTag2] = useState("");
  const [tag3, setTag3] = useState("");
  const [plot1, setPlot1] = useState([]);
  const [plot2, setPlot2] = useState([]);
  const [plot3, setPlot3] = useState([]);
  const [dateRangeVal, setDateRangeVal] = useState([null, null]);
  const [quickDate, setQuickDate] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("All Countries");
  const [geoToggle, setGeoToggle] = useState(false);
  // const [selectCountryOptions, setSelectCountryOptions] = useState([]);
  const [adOptions, setAdOptions] = useState({});
  const [selectedDevice, setSelectedDevice] = useState("All Devices");
  const [selectedSite, setSelectedSite] = useState("All Units");
  const [selectAdOptions, setSelectAdOptions] = useState("All Units");
  const [dashConfig, setDashConfig] = useState();
  const [filteredColumns, setFilteredColumns] = useState();
  const [revShare, setRevShare] = useState(100);
  const [showCountryDropdown, setShowCountryDropdown] = useState(true);
  const [showDeviceDropdown, setShowDeviceDropdown] = useState(true);

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "DATE",
      fixed: true,
      defaultSortOrder: "descend",
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_, record) => {
        return record.name ? (
          record.name.length > 25 ? (
            <>{record.name.slice(-25)}...</>
          ) : (
            record.name
          )
        ) : (
          record.date
        );
      },
    },
    {
      title: "Name",
      dataIndex: "siteName",
      key: "siteName",
    },
    {
      title: "Ad Requests",
      dataIndex: "adreq",
      key: "adreq",
      render: (_, record) => record.adreq,
    },
    {
      title: "Page Views",
      dataIndex: "pv",
      key: "pv",
    },
    {
      title: "Impressions",
      dataIndex: "total_imp",
      key: "imp",
    },
    {
      title: "Net Revenue",
      dataIndex: "net_rev",
      key: "rev",
      render: (_, record) => {
        return isNaN(record.total_rev)
          ? 0
          : "$" + ((Number(record.total_rev) * revShare) / 100).toFixed(2);
      },
    },
    {
      title: "Gross Revenue",
      dataIndex: "total_rev",
      key: "rev",
      render: (_, record) => {
        return isNaN(record.total_rev)
          ? 0
          : "$" + Number(record.total_rev).toFixed(2);
      },
    },
    {
      title: "Average ECPM  ",
      dataIndex: "avg_ecpm",
      key: "ecpm",
      render: (_, record) => {
        return isNaN(record.avg_ecpm)
          ? ""
          : "$" + Number(record.avg_ecpm).toFixed(2);
      },
    },
    {
      title: "Fill Rate",
      dataIndex: "fillrate",
      key: "fillrate",
      render: (_, record) => {
        return isNaN(record.fillrate)
          ? ""
          : Number(record.fillrate).toFixed(2) + "%";
      },
    },
    {
      title: "RPM",
      dataIndex: "rpm",
      key: "rpm",
      render: (_, record) => {
        return isNaN(record.rpm) ? "" : "$" + Number(record.rpm).toFixed(2);
      },
    },
    {
      title: "Total CTR",
      dataIndex: "total_CTR",
      key: "total_CTR",
      render: (_, record) => {
        return record.total_CTR + "%";
      },
    },
  ];

  const plotMappings = {
    imp: {
      graph: "impGraph",
      label: "Impressions",
      tag: "Total Imp",
    },
    rev: {
      graph: "revGraph",
      label: "Revenue",
      tag: "Total Rev",
    },
    rpm: {
      graph: "rpmGraph",
      label: "RPM",
      tag: "Average RPM",
    },
    ecpm: {
      graph: "ecpmGraph",
      label: "eCPM",
      tag: "Average eCPM",
    },
  };

  function createAdoptions(arr) {
    let result = {};

    arr.forEach((obj) => {
      const label = obj.label;

      if (!result[label]) {
        result[label] = {};
      }

      Object.keys(obj).forEach((key) => {
        if (key === "label") return;

        if (!result[label][key]) {
          result[label][key] = [];
        }

        if (Array.isArray(obj[key])) {
          result[label][key].push(...obj[key]);
        } else {
          result[label][key] = obj[key];
        }
      });
    });

    const allUnits = {};
    Object.keys(result).forEach((label) => {
      Object.keys(result[label]).forEach((key) => {
        if (!allUnits[key]) {
          allUnits[key] = [];
        }
        allUnits[key].push(result[label][key]);
      });
    });
    result["All Units"] = allUnits;

    return result;
  }

  function createSelectAdOptions(arr) {
    let result = [];

    result.push({ label: "All Units", value: "All Units" });

    arr.forEach((obj) => {
      result.push({ label: obj.label, value: obj.label });
    });

    return result;
  }

  function filterColumns(cols) {
    const newCols = cols.filter((col) =>
      dashConfig.tableCols.includes(col.dataIndex)
    );
    return newCols;
  }

  // async function getGamData(name) {
  //   const res = await fetchGamData({ username: name });
  //   setDashConfig(res.data.config);
  //   setRevShare(res.data?.revShare || 100);

  //   setAdOptions(createAdoptions(res.data.gamData));
  //   setSelectAdOptions(createSelectAdOptions(res.data.gamData));
  // }

  const getGamData = async (name) => {
    try {
      const res = await fetchGamData({ username: name });
      setDashConfig(res.data.config);
      setRevShare(res.data?.revShare || 100);
      setAdOptions(createAdoptions(res.data.gamData));
      setSelectAdOptions(createSelectAdOptions(res.data.gamData));
    } catch (error) {
      console.error("Error fetching GAM data:", error);
      alert("An error occurred while fetching GAM data. Please try again.");
    }
  };

  // async function getSelectCountryOptions() {
  //   const res = await axios.get(
  //     `${process.env.REACT_APP_GAMDASH_CDN}/selectCountryOptions.json`
  //   );
  //   setSelectCountryOptions(res.data);
  // }

  const handleQuickDateChange = (days) => {
    const today = new Date();
    const d1 = createDate(today);

    const startDate = new Date(today);
    startDate.setDate(today.getDate() - (days - 1));
    const d2 = createDate(startDate);

    const date1 = d2[2] + "-" + d2[1] + "-" + d2[0];
    const date2 = d1[2] + "-" + d1[1] + "-" + d1[0];

    setDateRangeVal([date1, date2]);
    setSearchDate(!searchDate);
    setQuickDate(`${days}D`);
  };

  const flattenData = (data) => {
    const result = [];
    const flatten = (record, parentKey) => {
      const { key, ...rest } = record;
      const currentKey = parentKey ? `${parentKey}.${key}` : key;
      result.push({
        key: currentKey,
        Date: rest.date,
        "Page Views": rest.pv,
        "Total Impression": rest.total_imp,
        "Total Revenue": rest.total_rev,
        "Average ECPM": rest.avg_ecpm,
        RPM: rest.rpm,
        "Fill Rate": rest.fillrate,
      });
    };
    data.forEach((record) => flatten(record));
    return result;
  };

  const flattenedData = flattenData(tableData).map((record) => {
    delete record.key;
    return record;
  });

  const handleLogout = () => {
    removeCookie("gam_token");
    destroyToken(token);
    navigate("/login");
  };

  useEffect(() => {
    const gam_token = cookies.gam_token;
    let userData = {};

    if (gam_token) {
      userData = JSON.parse(atob(gam_token.split(".")[3]));
      setToken(gam_token.split(".").slice(0, 3).join("."));

      setUserName(userData?.name);

      getGamData(userData?.name);
    }
  }, [cookies]);

  useEffect(() => {
    var today = new Date();
    var firstDay = new Date(today.getFullYear(), today.getMonth(), 1);

    var d = createDate(today);
    const d1 = d[2] + "-" + d[1] + "-" + d[0];
    d = createDate(firstDay);
    const d2 = d[2] + "-" + d[1] + "-" + d[0];
    setDateRangeVal([d2, d1]);

    setSearchDate(!searchDate);

    // getSelectCountryOptions();
  }, []);

  useEffect(() => {
    if (
      dateRangeVal[0] != null &&
      dateRangeVal[1] != null &&
      searchDate !== 0
    ) {
      const getData = async () => {
        setLoadingScreen(true);
        setTableData([]);

        // const userinfo = await getUserInfo();
        try {
          let res;
          if (
            (userName === "inuxu" || userName === "infomo") &&
            selectedSite === "All Units"
          )
            res = await axios.post(
              `${process.env.REACT_APP_CLIENTDASH_URL}/client/inuxu_data`,
              {
                dateStart: dateRangeVal[0],
                dateEnd: dateRangeVal[1],
                adUnitIDMain: adOptions[selectedSite]["idMain"],
                adUnitIDNewMain: adOptions[selectedSite]["idNewMain"],
                countryList: "ALL",
              },
              {
                headers: {
                  Authorization: token,
                },
              }
            );
          else if (userName === "allplaynews_adx") {
            setShowCountryDropdown(false);
            setShowDeviceDropdown(false);
            res = await axios.post(
              `${process.env.REACT_APP_CLIENTDASH_URL}/client/allplaynews_data`,
              {
                dateStart: dateRangeVal[0],
                dateEnd: dateRangeVal[1],
                adUnitIDMain: adOptions[selectedSite]["idMain"],
                adUnitIDNewMain: adOptions[selectedSite]["idNewMain"],
                GaPropertyId: adOptions[selectedSite]["GaPropertyId"],
              },
              {
                headers: {
                  Authorization: token,
                },
              }
            );
          } else
            res = await axios.post(
              `${process.env.REACT_APP_CLIENTDASH_URL}/client/device_country_DB`,
              {
                data: {
                  dateStart: dateRangeVal[0],
                  dateEnd: dateRangeVal[1],
                  deviceType: selectedDevice,
                  countryName: selectedCountry,
                  adUnitIDMain: adOptions[selectedSite]["idMain"],
                  adUnitIDNewMain: adOptions[selectedSite]["idNewMain"],
                  GaPropertyId: adOptions[selectedSite]["GaPropertyId"],
                },
              },
              {
                headers: {
                  Authorization: token,
                },
              }
            );

          setTableData(res.data.data);
          setTag1(res.data[dashConfig.plot1]);
          setTag2(res.data[dashConfig.plot2]);
          setTag3(res.data[dashConfig.plot3]);

          setPlot1(res.data[plotMappings[dashConfig.plot1].graph]);
          setPlot2(res.data[plotMappings[dashConfig.plot2].graph]);
          setPlot3(res.data[plotMappings[dashConfig.plot3].graph]);

          setFilteredColumns(filterColumns(columns));
        } catch (error) {
          console.error("Error fetching data:", error);
          alert(
            "An error occurred while fetching data. Please check your filters and try again."
          );
        }
        setLoadingScreen(false);
      };
      if (Object.keys(adOptions).length > 0) {
        getData();
      }
    }
  }, [searchDate, adOptions, selectedCountry, selectedDevice, selectedSite]);

  return (
    <>
      {loadingScreen ? (
        <LoaderComp />
      ) : (
        <div className="w-full">
          <div className="sm:px-2 md:px-4 lg:px-6">
            <div
              className={`grid gap-1 grid-cols-1 lg:grid-cols-5 justify-between items-center my-4`}
            >
              <div className="flex justify-center">
                <Logo />
              </div>

              <div className="flex justify-center lg:col-span-2">
                <div className="flex items-center my-3">
                  <Space direction="vertical" size={12}>
                    <RangePicker
                      value={
                        dateRangeVal[0] !== "" && dateRangeVal[1] !== ""
                          ? [dayjs(dateRangeVal[0]), dayjs(dateRangeVal[1])]
                          : undefined
                      }
                      onChange={(date, dateString) => {
                        setDateRangeVal(dateString);
                      }}
                    />
                  </Space>
                  <Button
                    style={{ marginLeft: 4 }}
                    type="primary"
                    onClick={() => setSearchDate(searchDate + 1)}
                  >
                    Search
                  </Button>
                </div>
              </div>

              <div className="flex justify-center">
                <Button
                  onClick={() => handleQuickDateChange(1)}
                  type={quickDate === "1D" ? "primary" : "default"}
                >
                  24H
                </Button>

                <Button
                  onClick={() => handleQuickDateChange(7)}
                  type={quickDate === "7D" ? "primary" : "default"}
                >
                  7D
                </Button>

                <Button
                  onClick={() => handleQuickDateChange(30)}
                  type={quickDate === "30D" ? "primary" : "default"}
                >
                  30D
                </Button>
              </div>

              <div className="flex justify-center">
                <Button
                  danger
                  type="text"
                  size="large"
                  icon={<LogoutOutlined />}
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              </div>
            </div>
            <div>
              <div>
                <div
                  className={`grid grid-cols-1 ${
                    geoToggle
                      ? // && geoEnable.includes(userName)
                        "lg:grid-cols-4"
                      : "lg:grid-cols-3"
                  } justify-between items-center my-4 h-auto lg:h-72`}
                >
                  {geoToggle && (
                    // && geoEnable.includes(userName)
                    <div>
                      {showCountryDropdown && (
                        <div className="flex align-top justify-center pb-4  ">
                          <Select
                            showSearch
                            style={{ width: "80%", maxWidth: 420 }}
                            placeholder="Select Country"
                            options={selectCountryOptions}
                            value={selectedCountry}
                            onChange={(val) => {
                              setSelectedCountry(val);
                            }}
                          ></Select>
                        </div>
                      )}
                      {showDeviceDropdown && (
                        <div className="flex align-top justify-center pb-4">
                          <Select
                            style={{ width: "80%", maxWidth: 420 }}
                            placeholder="Select Device"
                            options={selectDeviceOptions}
                            value={selectedDevice}
                            onChange={(val) => setSelectedDevice(val)}
                          ></Select>
                        </div>
                      )}
                      <div className="flex align-top justify-center pb-4">
                        <Select
                          showSearch
                          style={{
                            width: "80%",
                            maxWidth: 420,
                          }}
                          placeholder="Select Adunit"
                          options={selectAdOptions}
                          value={selectedSite}
                          onChange={(val) => {
                            setSelectedSite(val);
                          }}
                        ></Select>
                      </div>
                    </div>
                  )}
                  <div className="block h-64 lg:h-full w-full">
                    <div className="flex justify-center h-5/6 w-full">
                      <ResponsiveContainer width="100%" height="100%">
                        <AreaChart
                          data={plot1}
                          syncId="syncedTooltip"
                          margin={{ top: 10, bottom: 10, right: 20 }}
                        >
                          <defs>
                            <linearGradient
                              id="colorImp"
                              x1="0"
                              y1="0"
                              x2="0"
                              y2="1"
                            >
                              <stop
                                offset="5%"
                                stopColor="#2e7d32"
                                stopOpacity={0.4}
                              />
                              <stop
                                offset="95%"
                                stopColor="#2e7d32"
                                stopOpacity={0}
                              />
                            </linearGradient>
                          </defs>
                          <XAxis dataKey="name" />
                          <YAxis />
                          <Tooltip />
                          <Area
                            type="monotone"
                            dataKey={plotMappings[dashConfig?.plot1]?.label}
                            stroke="#2e7d32"
                            fillOpacity={1}
                            fill="url(#colorImp)"
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </div>
                    <div className="flex justify-center">
                      <Tag
                        color="green"
                        style={{
                          padding: "6px",
                          fontSize: "13px",
                          borderRadius: "8px",
                        }}
                      >
                        {`Total Imp: ${tag1}`}
                      </Tag>
                    </div>
                  </div>

                  <div className="block h-64 lg:h-full w-full">
                    <div className="flex justify-center h-5/6 w-full">
                      <ResponsiveContainer width="100%" height="100%">
                        <AreaChart
                          data={plot2}
                          syncId="syncedTooltip"
                          margin={{ top: 10, bottom: 10, right: 20 }}
                        >
                          <defs>
                            <linearGradient
                              id="colorRev"
                              x1="0"
                              y1="0"
                              x2="0"
                              y2="1"
                            >
                              <stop
                                offset="5%"
                                stopColor="#1976d2"
                                stopOpacity={0.4}
                              />
                              <stop
                                offset="95%"
                                stopColor="#1976d2"
                                stopOpacity={0}
                              />
                            </linearGradient>
                          </defs>

                          <XAxis
                            dataKey="name"
                            style={{ marginLeft: "50px" }}
                          />
                          <YAxis />
                          <Tooltip />
                          <Area
                            type="monotone"
                            dataKey={plotMappings[dashConfig?.plot2]?.label}
                            stroke="#1976d2"
                            fillOpacity={1}
                            fill="url(#colorRev)"
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </div>
                    <div className="flex justify-center">
                      <Tag
                        color="blue"
                        style={{
                          padding: "6px",
                          fontSize: "13px",
                          borderRadius: "8px",
                        }}
                      >
                        {`Total Rev: $${tag2}`}
                      </Tag>
                    </div>
                  </div>

                  <div className="block h-64 lg:h-full w-full">
                    <div className="flex justify-center h-5/6 w-full">
                      <ResponsiveContainer width="100%" height="100%">
                        <AreaChart
                          data={plot3}
                          syncId="syncedTooltip"
                          margin={{ top: 10, bottom: 10, right: 20 }}
                        >
                          <defs>
                            <linearGradient
                              id="colorRpm"
                              x1="0"
                              y1="0"
                              x2="0"
                              y2="1"
                            >
                              <stop
                                offset="5%"
                                stopColor="#e83462"
                                stopOpacity={0.4}
                              />
                              <stop
                                offset="95%"
                                stopColor="#e83462"
                                stopOpacity={0}
                              />
                            </linearGradient>
                          </defs>
                          <XAxis dataKey="name" />
                          <YAxis />
                          <Tooltip />
                          <Area
                            type="monotone"
                            dataKey={plotMappings[dashConfig?.plot3]?.label}
                            stroke="#e83462"
                            fillOpacity={1}
                            fill="url(#colorRpm)"
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </div>
                    <div className="flex justify-center">
                      <Tag
                        color="error"
                        style={{
                          padding: "6px",
                          fontSize: "13px",
                          borderRadius: "8px",
                        }}
                      >
                        {`${plotMappings[dashConfig?.plot3]?.tag}: $${tag3}`}
                      </Tag>
                    </div>
                  </div>
                </div>

                <div className={`grid grid-cols-2 lg:grid-cols-2 mb-2 px-2`}>
                  <div className="flex space-x-2 justify-center my-2 lg:my-0">
                    <Switch
                      // value={geoToggle}
                      checked={geoToggle}
                      onChange={() => {
                        setSelectedCountry("All Countries");
                        setSelectedDevice("All Devices");
                        setSelectedSite("All Units");
                        setGeoToggle(!geoToggle);
                      }}
                    />
                    <Text strong>
                      {geoToggle
                        ? userName === "allplaynews_adx"
                          ? "SiteWise"
                          : "GeoWise"
                        : "Combined"}
                    </Text>
                  </div>

                  <div className="flex justify-center my-2 lg:my-0">
                    <Button
                      type="primary"
                      size="small"
                      style={{
                        backgroundColor: "#1B5E20",
                        borderColor: "#1B5E20",
                      }}
                    >
                      <CSVLink
                        filename={"DataTable.csv"}
                        data={flattenedData}
                        className="btn btn-primary"
                        style={{ textDecoration: "none" }} // Remove underline from text
                      >
                        <Typography variant="h5" sx={{ color: "#FFFFFF" }}>
                          <span style={{ color: "#FFFFFF" }}>
                            Export to CSV
                          </span>
                        </Typography>
                      </CSVLink>
                    </Button>
                  </div>
                </div>
                <div className="flex">
                  <Table
                    bordered
                    columns={filteredColumns}
                    pagination={{
                      defaultPageSize: 10,
                      showSizeChanger: true,
                      pageSizeOptions: ["10", "20", "50", "100"],
                    }}
                    dataSource={tableData}
                    scroll={{
                      x: 1000,
                      y: 800,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Dashboard;
