/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { LogoutOutlined } from "@ant-design/icons";
import axios from "axios";
import {
  Select,
  Switch,
  DatePicker,
  Space,
  Typography,
  Button,
  Tag,
  Table,
} from "antd";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import dayjs from "dayjs";
import {
  AreaChart,
  ResponsiveContainer,
  Area,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import "../index.css";
import LoaderComp from "../Utility/LoaderComp";
import { useCookies } from "react-cookie";
import { destroyToken, fetchGamData } from "../API/User";
import getUserInfo from "../helpers/getUserInfo";

const { RangePicker } = DatePicker;
const { Text } = Typography;

const selectDeviceOptions = [
  { label: "All Devices", value: "All Devices" },
  { label: "Desktop", value: "Desktop" },
  { label: "Tablet", value: "Tablet" },
  { label: "Smartphone", value: "Smartphone" },
  { label: "Connected TV", value: "Connected TV" },
];

const createDate = (today) => {
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  return [dd, mm, yyyy];
};

// const getCurrencySymbol = (currency) => {
//   if (currency === "USD") return "$";
//   else if (currency === "INR") return "₹";
//   else if (currency === "AED") return "د.إ";
//   else return "$";
// };

function Dashboard(props) {
  // const [userName, setUserName] = useState("");
  const [cookies, , removeCookie] = useCookies(["gam_token"]);
  const [token, setToken] = useState("");
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [searchDate, setSearchDate] = useState(0);

  const navigate = useNavigate();

  const [tag1, setTag1] = useState("");
  const [tag2, setTag2] = useState("");
  const [tag3, setTag3] = useState("");
  const [plot1, setPlot1] = useState([]);
  const [plot2, setPlot2] = useState([]);
  const [plot3, setPlot3] = useState([]);
  const [dateRangeVal, setDateRangeVal] = useState([null, null]);
  const [quickDate, setQuickDate] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("All Countries");
  // const [filterSubmit, setFilterSubmit] = useState(false);
  const [geoToggle, setGeoToggle] = useState(false);
  const [selectCountryOptions, setSelectCountryOptions] = useState([]);
  const [adOptions, setAdOptions] = useState({});
  const [selectedDevice, setSelectedDevice] = useState("All Devices");
  const [selectedSite, setSelectedSite] = useState("All Units");
  const [selectAdOptions, setSelectAdOptions] = useState("All Units");
  // const [gamData, setGamData] = useState();
  const [dashConfig, setDashConfig] = useState();
  const [filteredColumns, setFilteredColumns] = useState();

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "DATE",
      fixed: true,
      defaultSortOrder: "descend",
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Page Views",
      dataIndex: "pv",
      key: "pv",
    },
    {
      title: "Impressions",
      dataIndex: "total_imp",
      key: "imp",
    },
    {
      title: "Net Revenue",
      dataIndex: "net_rev",
      key: "rev",
      render: (_, record) => {
        return isNaN(record.total_rev)
          ? 0
          : "$" + (Number(record.total_rev) * 0.8).toFixed(2);
      },
    },
    {
      title: "Gross Revenue",
      dataIndex: "total_rev",
      key: "rev",
      render: (_, record) => {
        return isNaN(record.total_rev)
          ? 0
          : "$" + Number(record.total_rev).toFixed(2);
      },
    },
    {
      title: "Average ECPM  ",
      dataIndex: "avg_ecpm",
      key: "ecpm",
      render: (_, record) => {
        return isNaN(record.avg_ecpm)
          ? "NA"
          : "$" + Number(record.avg_ecpm).toFixed(2);
      },
    },
    {
      title: "Fill Rate",
      dataIndex: "fillrate",
      key: "fillrate",
      render: (_, record) => {
        return isNaN(record.fillrate)
          ? "NA"
          : Number(record.fillrate).toFixed(2) + "%";
      },
    },
    {
      title: "RPM",
      dataIndex: "rpm",
      key: "rpm",
      render: (_, record) => {
        return isNaN(record.rpm) ? "NA" : "$" + Number(record.rpm).toFixed(2);
      },
    },
    {
      title: "Total CTR",
      dataIndex: "total_CTR",
      key: "total_CTR",
      render: (_, record) => {
        return record.total_CTR + "%";
      },
    },
  ];

  const plotMappings = {
    imp: {
      graph: "impGraph",
      label: "Impressions",
      tag: "Total Imp",
    },
    rev: {
      graph: "revGraph",
      label: "Revenue",
      tag: "Total Rev",
    },
    rpm: {
      graph: "rpmGraph",
      label: "RPM",
      tag: "Average RPM",
    },
    ecpm: {
      graph: "ecpmGraph",
      label: "eCPM",
      tag: "Average eCPM",
    },
  };

  function createAdoptions(arr) {
    let result = {};

    arr.forEach((obj) => {
      const label = obj.label;

      if (!result[label]) {
        result[label] = {};
      }

      Object.keys(obj).forEach((key) => {
        if (key === "label") return;

        if (!result[label][key]) {
          result[label][key] = [];
        }

        if (Array.isArray(obj[key])) {
          result[label][key].push(...obj[key]);
        } else {
          result[label][key] = obj[key];
        }
      });
    });

    const allUnits = {};
    Object.keys(result).forEach((label) => {
      Object.keys(result[label]).forEach((key) => {
        if (!allUnits[key]) {
          allUnits[key] = [];
        }
        allUnits[key].push(result[label][key]);
      });
    });
    result["All Units"] = allUnits;

    return result;
  }

  function createSelectAdOptions(arr) {
    let result = [];

    result.push({ label: "All Units", value: "All Units" });

    arr.forEach((obj) => {
      result.push({ label: obj.label, value: obj.label });
    });

    return result;
  }

  function filterColumns(cols) {
    const newCols = cols.filter((col) =>
      dashConfig.tableCols.includes(col.dataIndex)
    );
    return newCols;
  }

  async function getGamData(name) {
    const res = await fetchGamData({ username: name });
    // setGamData(res.data.gamData);
    setDashConfig(res.data.config);

    setAdOptions(createAdoptions(res.data.gamData));
    setSelectAdOptions(createSelectAdOptions(res.data.gamData));
  }

  async function getSelectCountryOptions() {
    const res = await axios.get(
      `${process.env.REACT_APP_GAMDASH_CDN}/selectCountryOptions.json`
    );
    // const removeAllCountries = res.data.filter(
    //   (obj) => obj.value !== "All Countries"
    // );
    setSelectCountryOptions(res.data);
  }

  const handleQuickDateChange = (days) => {
    const today = new Date();
    const d1 = createDate(today);

    const startDate = new Date(today);
    startDate.setDate(today.getDate() - (days - 1));
    const d2 = createDate(startDate);

    const date1 = d2[2] + "-" + d2[1] + "-" + d2[0];
    const date2 = d1[2] + "-" + d1[1] + "-" + d1[0];

    setDateRangeVal([date1, date2]);
    setSearchDate(!searchDate);
    setQuickDate(`${days}D`);
  };

  const flattenData = (data) => {
    const result = [];
    const flatten = (record, parentKey) => {
      const { key, ...rest } = record;
      const currentKey = parentKey ? `${parentKey}.${key}` : key;
      result.push({
        key: currentKey,
        Date: rest.date,
        "Page Views": rest.pv,
        "Total Impression": rest.total_imp,
        "Total Revenue": rest.total_rev,
        "Average ECPM": rest.avg_ecpm,
        RPM: rest.rpm,
        "Fill Rate": rest.fillrate,
      });
    };
    data.forEach((record) => flatten(record));
    return result;
  };

  const flattenedData = flattenData(tableData).map((record) => {
    delete record.key;
    return record;
  });

  const handleLogout = () => {
    removeCookie("gam_token");
    destroyToken(token);
    navigate("/login");
  };

  useEffect(() => {
    const gam_token = cookies.gam_token;
    let userData = {};

    if (gam_token) {
      userData = JSON.parse(atob(gam_token.split(".")[3]));
      setToken(gam_token.split(".").slice(0, 3).join("."));

      // setUserName(userData?.username);

      getGamData(userData?.name);
    }
  }, [cookies]);

  useEffect(() => {
    var today = new Date();
    var firstDay = new Date(today.getFullYear(), today.getMonth(), 1);

    var d = createDate(today);
    const d1 = d[2] + "-" + d[1] + "-" + d[0];
    d = createDate(firstDay);
    const d2 = d[2] + "-" + d[1] + "-" + d[0];
    setDateRangeVal([d2, d1]);

    setSearchDate(!searchDate);

    getSelectCountryOptions();
  }, []);

  useEffect(() => {
    if (
      dateRangeVal[0] != null &&
      dateRangeVal[1] != null &&
      searchDate !== 0
    ) {
      const getData = async () => {
        setLoadingScreen(true);
        setTableData([]);

        const userinfo = await getUserInfo();

        const res = await axios.post(
          `${process.env.REACT_APP_CLIENTDASH_URL}/client/device_country_DB`,
          {
            data: {
              dateStart: dateRangeVal[0],
              dateEnd: dateRangeVal[1],
              deviceType: selectedDevice,
              countryName: selectedCountry,
              adUnitIDMain: adOptions[selectedSite]["idMain"],
              adUnitIDNewMain: adOptions[selectedSite]["idNewMain"],
              GaPropertyId: adOptions[selectedSite]["GaPropertyId"],
            },
            userinfo,
          },
          {
            headers: {
              // "x-api-key": process.env.REACT_APP_API_KEY,
              Authorization: token,
            },
          }
        );

        setTableData(res.data.data);
        setTag1(res.data[dashConfig.plot1]);
        setTag2(res.data[dashConfig.plot2]);
        setTag3(res.data[dashConfig.plot3]);

        setPlot1(res.data[plotMappings[dashConfig.plot1].graph]);
        setPlot2(res.data[plotMappings[dashConfig.plot2].graph]);
        setPlot3(res.data[plotMappings[dashConfig.plot3].graph]);

        setFilteredColumns(filterColumns(columns));

        setLoadingScreen(false);
      };
      if (Object.keys(adOptions).length > 0) {
        getData();
      }
    }
  }, [searchDate, adOptions, selectedCountry, selectedDevice, selectedSite]);

  return (
    <>
      {loadingScreen ? (
        <LoaderComp />
      ) : (
        <div className="w-full">
          <div className="sm:px-2 md:px-4 lg:px-6">
            <div
              className={`grid gap-1 grid-cols-1 lg:grid-cols-5 justify-between items-center my-4`}
            >
              <div className="flex justify-center">
                <svg
                  width="180"
                  height="80"
                  viewBox="0 0 1352 186"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 144.371V56.0952L21.633 42.3667V133.595L70.9326 159.724V186L0 144.371Z"
                    fill="black"
                  />
                  <path
                    d="M166 144.371V56.0952L144.367 42.3667V133.595L95.0674 159.724V186L166 144.371Z"
                    fill="black"
                  />
                  <path
                    d="M127.738 51.3714L144.514 42.3667V69.8238L116.112 88.7191L127.738 51.3714Z"
                    fill="black"
                  />
                  <path
                    d="M70.9326 159.724V32.0789V7.52857L49.8883 19.781V133.595L70.9326 159.724Z"
                    fill="black"
                  />
                  <path
                    d="M95.0674 159.724V32.0789V7.52857L116.112 19.781V133.595L95.0674 159.724Z"
                    fill="black"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M116.112 19.781L95.0674 7.52857V32.0789L116.112 44.2577V19.781ZM70.9326 32.0789V7.52857L49.8883 19.781V44.2577L70.9326 32.0789Z"
                    fill="black"
                  />
                  <path
                    d="M414 56H396V129.8H306V56H288V129.8C288 129.8 288 146 304.2 146H397.8C414.54 146 414 129.8 414 129.8V56Z"
                    fill="black"
                  />
                  <path
                    d="M468.07 56V146H486.07V77.6L567.07 146H585.07V56H567.07V124.4L486.07 56H468.07Z"
                    fill="black"
                  />
                  <path d="M657 56H639V146H657V56Z" fill="black" />
                  <path
                    d="M710.965 56V146H820.765C836.965 146 836.965 129.8 836.965 129.8V106.4C836.965 101 831.565 101 831.565 101C836.965 101 836.965 95.6 836.965 95.6V72.2C836.965 56 820.765 56 820.765 56H710.965ZM818.965 92H728.965V72.2H818.965V92ZM818.965 129.8H728.965V108.2H818.965V129.8Z"
                    fill="black"
                  />
                  <path
                    d="M999.035 56H909.035C891.035 56 891.035 72.2 891.035 72.2V129.8C891.035 129.8 891.035 146 909.035 146H999.035C1017.04 146 1017.04 129.8 1017.04 129.8V72.2C1017.04 72.2 1017.04 56 999.035 56ZM999.035 72.2V129.8H909.035V72.2H999.035Z"
                    fill="black"
                  />
                  <path
                    d="M1184.51 56H1058.51V72.2H1112.51V146H1130.51V72.2H1184.51V56Z"
                    fill="black"
                  />
                  <path
                    d="M1333.86 92H1243.86V72.2H1350.06V56H1243.86C1225.86 56 1225.86 72.2 1225.86 72.2V92C1225.86 92 1225.86 108.2 1243.86 108.2H1333.86V129.8H1225.86V146H1333.86C1351.86 146 1351.86 129.8 1351.86 129.8V108.2C1351.86 108.2 1351.86 92 1333.86 92Z"
                    fill="black"
                  />
                </svg>
              </div>

              <div className="flex justify-center lg:col-span-2">
                <div className="flex items-center my-3">
                  <Space direction="vertical" size={12}>
                    <RangePicker
                      value={
                        dateRangeVal[0] !== "" && dateRangeVal[1] !== ""
                          ? [dayjs(dateRangeVal[0]), dayjs(dateRangeVal[1])]
                          : undefined
                      }
                      onChange={(date, dateString) => {
                        setDateRangeVal(dateString);
                      }}
                    />
                  </Space>
                  <Button
                    style={{ marginLeft: 4 }}
                    type="primary"
                    onClick={() => setSearchDate(searchDate + 1)}
                  >
                    Search
                  </Button>
                </div>
              </div>

              <div className="flex justify-center">
                <Button
                  onClick={() => handleQuickDateChange(1)}
                  type={quickDate === "1D" ? "primary" : "default"}
                >
                  24H
                </Button>

                <Button
                  onClick={() => handleQuickDateChange(7)}
                  type={quickDate === "7D" ? "primary" : "default"}
                >
                  7D
                </Button>

                <Button
                  onClick={() => handleQuickDateChange(30)}
                  type={quickDate === "30D" ? "primary" : "default"}
                >
                  30D
                </Button>
              </div>

              <div className="flex justify-center">
                <Button
                  danger
                  type="text"
                  size="large"
                  icon={<LogoutOutlined />}
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              </div>
            </div>
            <div>
              <div>
                <div
                  className={`grid grid-cols-1 ${
                    geoToggle
                      ? // && geoEnable.includes(userName)
                        "lg:grid-cols-4"
                      : "lg:grid-cols-3"
                  } justify-between items-center my-4 h-auto lg:h-72`}
                >
                  {geoToggle && (
                    // && geoEnable.includes(userName)
                    <div>
                      <div className="flex align-top justify-center pb-4  ">
                        <Select
                          showSearch
                          style={{ width: "80%", maxWidth: 420 }}
                          placeholder="Select Country"
                          options={selectCountryOptions}
                          value={selectedCountry}
                          onChange={(val) => {
                            setSelectedCountry(val);
                          }}
                        ></Select>
                      </div>
                      <div className="flex align-top justify-center pb-4">
                        <Select
                          style={{ width: "80%", maxWidth: 420 }}
                          placeholder="Select Device"
                          options={selectDeviceOptions}
                          value={selectedDevice}
                          onChange={(val) => setSelectedDevice(val)}
                        ></Select>
                      </div>
                      <div className="flex align-top justify-center pb-4">
                        <Select
                          showSearch
                          style={{
                            width: "80%",
                            maxWidth: 420,
                          }}
                          placeholder="Select Adunit"
                          options={selectAdOptions}
                          value={selectedSite}
                          onChange={(val) => {
                            setSelectedSite(val);
                          }}
                        ></Select>
                      </div>
                      {/* <div className="flex align-top justify-center">
                        <Button
                          type="primary"
                          sx={{ px: 2 }}
                          onClick={() => setFilterSubmit(!filterSubmit)}
                        >
                          Apply
                        </Button>
                      </div> */}
                    </div>
                  )}
                  <div className="block h-64 lg:h-full w-full">
                    <div className="flex justify-center h-5/6 w-full">
                      <ResponsiveContainer width="100%" height="100%">
                        <AreaChart
                          data={plot1}
                          syncId="syncedTooltip"
                          margin={{ top: 10, bottom: 10, right: 20 }}
                        >
                          <defs>
                            <linearGradient
                              id="colorImp"
                              x1="0"
                              y1="0"
                              x2="0"
                              y2="1"
                            >
                              <stop
                                offset="5%"
                                stopColor="#2e7d32"
                                stopOpacity={0.4}
                              />
                              <stop
                                offset="95%"
                                stopColor="#2e7d32"
                                stopOpacity={0}
                              />
                            </linearGradient>
                          </defs>
                          <XAxis dataKey="name" />
                          <YAxis />
                          <Tooltip />
                          {/* <Legend margin={{ right: 1 }} /> */}
                          <Area
                            type="monotone"
                            dataKey={plotMappings[dashConfig?.plot1]?.label}
                            stroke="#2e7d32"
                            fillOpacity={1}
                            fill="url(#colorImp)"
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </div>
                    <div className="flex justify-center">
                      <Tag
                        color="green"
                        style={{
                          padding: "6px",
                          fontSize: "13px",
                          borderRadius: "8px",
                        }}
                      >
                        {`Total Imp: ${tag1}`}
                      </Tag>
                    </div>
                  </div>

                  <div className="block h-64 lg:h-full w-full">
                    <div className="flex justify-center h-5/6 w-full">
                      <ResponsiveContainer width="100%" height="100%">
                        <AreaChart
                          data={plot2}
                          syncId="syncedTooltip"
                          margin={{ top: 10, bottom: 10, right: 20 }}
                        >
                          <defs>
                            <linearGradient
                              id="colorRev"
                              x1="0"
                              y1="0"
                              x2="0"
                              y2="1"
                            >
                              <stop
                                offset="5%"
                                stopColor="#1976d2"
                                stopOpacity={0.4}
                              />
                              <stop
                                offset="95%"
                                stopColor="#1976d2"
                                stopOpacity={0}
                              />
                            </linearGradient>
                          </defs>

                          <XAxis
                            dataKey="name"
                            style={{ marginLeft: "50px" }}
                          />
                          <YAxis />
                          <Tooltip />
                          {/* <Legend /> */}
                          <Area
                            type="monotone"
                            dataKey={plotMappings[dashConfig?.plot2]?.label}
                            stroke="#1976d2"
                            fillOpacity={1}
                            fill="url(#colorRev)"
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </div>
                    <div className="flex justify-center">
                      <Tag
                        color="blue"
                        style={{
                          padding: "6px",
                          fontSize: "13px",
                          borderRadius: "8px",
                        }}
                      >
                        {`Total Rev: $${tag2}`}
                      </Tag>
                    </div>
                  </div>

                  <div className="block h-64 lg:h-full w-full">
                    <div className="flex justify-center h-5/6 w-full">
                      <ResponsiveContainer width="100%" height="100%">
                        <AreaChart
                          data={plot3}
                          syncId="syncedTooltip"
                          margin={{ top: 10, bottom: 10, right: 20 }}
                        >
                          <defs>
                            <linearGradient
                              id="colorRpm"
                              x1="0"
                              y1="0"
                              x2="0"
                              y2="1"
                            >
                              <stop
                                offset="5%"
                                stopColor="#e83462"
                                stopOpacity={0.4}
                              />
                              <stop
                                offset="95%"
                                stopColor="#e83462"
                                stopOpacity={0}
                              />
                            </linearGradient>
                          </defs>
                          <XAxis dataKey="name" />
                          <YAxis />
                          <Tooltip />
                          <Area
                            type="monotone"
                            dataKey={plotMappings[dashConfig?.plot3]?.label}
                            stroke="#e83462"
                            fillOpacity={1}
                            fill="url(#colorRpm)"
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </div>
                    <div className="flex justify-center">
                      <Tag
                        color="error"
                        style={{
                          padding: "6px",
                          fontSize: "13px",
                          borderRadius: "8px",
                        }}
                      >
                        {`${plotMappings[dashConfig?.plot3]?.tag}: $${tag3}`}
                      </Tag>
                    </div>
                  </div>
                </div>

                <div className={`grid grid-cols-2 lg:grid-cols-2 mb-2 px-2`}>
                  <div className="flex space-x-2 justify-center my-2 lg:my-0">
                    <Switch
                      // value={geoToggle}
                      checked={geoToggle}
                      onChange={() => {
                        setSelectedCountry("All Countries");
                        setSelectedDevice("All Devices");
                        setSelectedSite("All Units");
                        setGeoToggle(!geoToggle);
                      }}
                    />
                    <Text strong>{geoToggle ? "GeoWise" : "Combined"}</Text>
                  </div>

                  <div className="flex justify-center my-2 lg:my-0">
                    <Button
                      type="primary"
                      size="small"
                      style={{
                        backgroundColor: "#1B5E20",
                        borderColor: "#1B5E20",
                      }}
                    >
                      <CSVLink
                        filename={"DataTable.csv"}
                        data={flattenedData}
                        className="btn btn-primary"
                        style={{ textDecoration: "none" }} // Remove underline from text
                      >
                        <Typography variant="h5" sx={{ color: "#FFFFFF" }}>
                          <span style={{ color: "#FFFFFF" }}>
                            Export to CSV
                          </span>
                        </Typography>
                      </CSVLink>
                    </Button>
                  </div>
                </div>
                <div className="flex">
                  <Table
                    bordered
                    columns={filteredColumns}
                    pagination={{
                      defaultPageSize: 10,
                      showSizeChanger: true,
                      pageSizeOptions: ["10", "20", "50", "100"],
                    }}
                    dataSource={tableData}
                    scroll={{
                      x: 1000,
                      y: 800,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Dashboard;
