export default function Logo() {
  return (
    <svg
      width="180"
      height="80"
      viewBox="0 0 1352 186"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 144.371V56.0952L21.633 42.3667V133.595L70.9326 159.724V186L0 144.371Z"
        fill="black"
      />
      <path
        d="M166 144.371V56.0952L144.367 42.3667V133.595L95.0674 159.724V186L166 144.371Z"
        fill="black"
      />
      <path
        d="M127.738 51.3714L144.514 42.3667V69.8238L116.112 88.7191L127.738 51.3714Z"
        fill="black"
      />
      <path
        d="M70.9326 159.724V32.0789V7.52857L49.8883 19.781V133.595L70.9326 159.724Z"
        fill="black"
      />
      <path
        d="M95.0674 159.724V32.0789V7.52857L116.112 19.781V133.595L95.0674 159.724Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.112 19.781L95.0674 7.52857V32.0789L116.112 44.2577V19.781ZM70.9326 32.0789V7.52857L49.8883 19.781V44.2577L70.9326 32.0789Z"
        fill="black"
      />
      <path
        d="M414 56H396V129.8H306V56H288V129.8C288 129.8 288 146 304.2 146H397.8C414.54 146 414 129.8 414 129.8V56Z"
        fill="black"
      />
      <path
        d="M468.07 56V146H486.07V77.6L567.07 146H585.07V56H567.07V124.4L486.07 56H468.07Z"
        fill="black"
      />
      <path d="M657 56H639V146H657V56Z" fill="black" />
      <path
        d="M710.965 56V146H820.765C836.965 146 836.965 129.8 836.965 129.8V106.4C836.965 101 831.565 101 831.565 101C836.965 101 836.965 95.6 836.965 95.6V72.2C836.965 56 820.765 56 820.765 56H710.965ZM818.965 92H728.965V72.2H818.965V92ZM818.965 129.8H728.965V108.2H818.965V129.8Z"
        fill="black"
      />
      <path
        d="M999.035 56H909.035C891.035 56 891.035 72.2 891.035 72.2V129.8C891.035 129.8 891.035 146 909.035 146H999.035C1017.04 146 1017.04 129.8 1017.04 129.8V72.2C1017.04 72.2 1017.04 56 999.035 56ZM999.035 72.2V129.8H909.035V72.2H999.035Z"
        fill="black"
      />
      <path
        d="M1184.51 56H1058.51V72.2H1112.51V146H1130.51V72.2H1184.51V56Z"
        fill="black"
      />
      <path
        d="M1333.86 92H1243.86V72.2H1350.06V56H1243.86C1225.86 56 1225.86 72.2 1225.86 72.2V92C1225.86 92 1225.86 108.2 1243.86 108.2H1333.86V129.8H1225.86V146H1333.86C1351.86 146 1351.86 129.8 1351.86 129.8V108.2C1351.86 108.2 1351.86 92 1333.86 92Z"
        fill="black"
      />
    </svg>
  );
}
