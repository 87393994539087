import { Routes, Route } from "react-router-dom";
import Dashboard from "./Screens/Dashboard";
import ProtectedRoute from "./Screens/ProtectedRoute";
import Login from "./Auth/Login";
import Admin from "./Screens/Admin";

function App() {
  return (
    <Routes>
      <Route
        exact
        path="/gam"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/admin"
        element={
          <ProtectedRoute>
            <Admin />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/"
        element={
          <ProtectedRoute>
            <Login />
          </ProtectedRoute>
        }
      />
      <Route exact path="/login" element={<Login />} />
    </Routes>
  );
}

export default App;
