import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Modal,
  Typography,
  Layout,
  Select,
  Collapse,
  Space,
} from "antd";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  CloseCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { createGAMUser, destroyToken } from "../API/User";
import axios from "axios";

const { Text, Title } = Typography;
const { Header, Content } = Layout;
const { Option } = Select;
const { Panel } = Collapse;

const plotOptions = ["Imp", "Rev", "ECPM", "RPM"];

const columnsOptions = [
  { value: "pv", label: "Page Views" },
  { value: "total_imp", label: "Impressions" },
  // { value: "total_rev", label: "Net Revenue" },
  { value: "total_rev", label: "Revenue" },
  { value: "avg_ecpm", label: "Average ECPM" },
  { value: "fillrate", label: "Fill Rate" },
  // { value: "eff_fill_rate", label: "Eff Fill Rate" },
  { value: "adreq", label: "Ad Requests" },
  { value: "rpm", label: "RPM" },
  { value: "total_CTR", label: "total_CTR" },
];

const CreateUserModal = () => {
  const [cookies, , removeCookie] = useCookies(["gam_token"]);
  const [token, setToken] = useState("");
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const onFinish = async (values) => {
    setLoading(true);
    try {
      const {
        name,
        username,
        password,
        plot1,
        plot2,
        plot3,
        columns,
        gamData = [],
      } = values;
      const tableCols = columns.includes("date")
        ? columns
        : [...columns, "date"];

      const userData = {
        name,
        username,
        password,
        type: "GAM",
        config: {
          plot1,
          plot2,
          plot3,
          tableCols,
        },
        gamData,
      };
      await createGAMUser(userData);
      alert("User created successfully!");
      setVisible(false);
    } catch (error) {
      alert(error.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    removeCookie("gam_token");
    destroyToken(token);
    navigate("/login");
  };

  useEffect(() => {
    const gam_token = cookies.gam_token;

    if (gam_token) setToken(gam_token.split(".").slice(0, 3).join("."));
  }, [cookies]);

  return (
    <>
      <Layout>
        <Header
          style={{
            paddingInline: "30px",
            display: "flex",
            alignItems: "center",
            background: "#1976D2",
            justifyContent: "space-between",
            width: "100%",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.5)",
            height: "10vh",
          }}
        >
          <Text className="text-white text-xl font-normal">Unibots</Text>
          <div className="flex justify-center">
            <Button
              danger
              type="text"
              size="large"
              icon={<LogoutOutlined />}
              onClick={handleLogout}
            >
              Logout
            </Button>
          </div>
        </Header>
        <Content>
          <div className="p-4 flex flex-col items-center">
            <div className="w-full flex flex-col md:flex-row md:justify-between items-center">
              <Title
                level={4}
                style={{ margin: 0 }}
                className="text-center md:text-left"
              >
                Create GAM Dashboards
              </Title>
              <div className="flex flex-col md:flex-row mt-4 md:mt-0 space-y-2 md:space-y-0 md:space-x-2">
                <Button type="primary" onClick={() => setVisible(true)}>
                  Create User
                </Button>
              </div>
            </div>
          </div>
          <Modal
            open={visible}
            onCancel={() => setVisible(false)}
            footer={null}
            // centered
            destroyOnClose
            closeIcon={<CloseCircleOutlined />}
            style={{ maxHeight: "80vh", overflowY: "auto", maxWidth: "80vh" }}
          >
            <div className=" p-4">
              <Form
                name="userForm"
                layout="horizontal"
                onFinish={onFinish}
                initialValues={{
                  name: "",
                  username: "",
                  password: "",
                  gamData: [],
                  columns: [],
                }}
                labelCol={{ span: 8 }} // Set label column width
                wrapperCol={{ span: 15 }} // Set wrapper column width
              >
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[
                    { required: true, message: "Please enter your name" },
                  ]}
                >
                  <Input placeholder="Name" />
                </Form.Item>

                <Form.Item
                  label="Username"
                  name="username"
                  rules={[
                    { required: true, message: "Please enter your username" },
                  ]}
                >
                  <Input placeholder="Username" />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    { required: true, message: "Please enter your password" },
                    {
                      validator: (_, value) => {
                        if (value && value.length < 12) {
                          return Promise.reject(
                            new Error(
                              "Password must be at least 12 characters long."
                            )
                          );
                        } else if (
                          value &&
                          value.length >= 12 &&
                          !/\d/.test(value)
                        ) {
                          return Promise.reject(
                            new Error(
                              "Password must contain at least one number."
                            )
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="Password"
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>

                {[1, 2, 3].map((plotNumber) => (
                  <Form.Item
                    key={`plot${plotNumber}`}
                    label={`Plot ${plotNumber}`}
                    name={`plot${plotNumber}`}
                    rules={[
                      {
                        required: true,
                        message: `Please select Plot ${plotNumber}`,
                      },
                    ]}
                  >
                    <Select placeholder={`Select Plot ${plotNumber}`}>
                      {plotOptions.map((option) => (
                        <Option key={option} value={option.toLowerCase()}>
                          {option}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                ))}

                <Form.Item
                  label="Columns"
                  name="columns"
                  rules={[{ required: true, message: "Please select columns" }]}
                >
                  <Select
                    mode="multiple"
                    placeholder="Select Columns"
                    allowClear
                  >
                    {columnsOptions.map((option) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.List name="gamData">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => (
                        <Collapse
                          key={field.key}
                          defaultActiveKey={[field.key]}
                          style={{ marginBottom: 12 }}
                        >
                          <Panel
                            header={`GAM Data ${index + 1}`}
                            key={field.key + 10000}
                          >
                            <Space
                              direction="vertical"
                              style={{ display: "flex" }}
                              size="small"
                            >
                              <Form.Item
                                {...field}
                                name={[field.name, "label"]}
                                label="Label"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter label",
                                  },
                                ]}
                              >
                                <Input placeholder="Label" />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                name={[field.name, "isPlacement"]}
                                label="Placement"
                                rules={[
                                  {
                                    required: true,
                                    message: "Is this a placement?",
                                  },
                                ]}
                              >
                                <Select placeholder={`Placement?`}>
                                  {["true", "false"].map((option) => (
                                    <Option
                                      key={option}
                                      value={option.toLowerCase()}
                                    >
                                      {option}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                              <Form.Item
                                {...field}
                                name={[field.name, "GA"]}
                                label="GA"
                              >
                                <Input placeholder="GA" type="number" />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                name={[field.name, "nameMain"]}
                                label="Name Main"
                              >
                                <Input placeholder="Name Main" />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                name={[field.name, "idMain"]}
                                label="ID Main"
                              >
                                <Input placeholder="ID Main" type="number" />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                name={[field.name, "nameNewMain"]}
                                label="Name NewMain"
                              >
                                <Input placeholder="Name NewMain" />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                name={[field.name, "idNewMain"]}
                                label="ID NewMain"
                              >
                                <Input placeholder="ID NewMain" type="number" />
                              </Form.Item>

                              <Button
                                type="dashed"
                                danger
                                onClick={() => remove(field.name)}
                                icon={<MinusCircleOutlined />}
                              >
                                {`Remove GAM Data ${index + 1}`}
                              </Button>
                            </Space>
                          </Panel>
                        </Collapse>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                        >
                          Add GAM Data
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>

                <Form.Item
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    // className="w-full"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Modal>
        </Content>
      </Layout>
    </>
  );
};

export default CreateUserModal;
