import { createSlice } from "@reduxjs/toolkit";

export const gamDataSlice = createSlice({
  name: "user",
  initialState: { data: [] },
  reducers: {
    updateGamData: (state, action) => {
      state.data = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateGamData } = gamDataSlice.actions;

export default gamDataSlice.reducer;
