import axios from "./axiosInstance";

const createGAMUser = async (userData) => {
  try {
    const res = await axios({
      url: "user/create_user",
      method: "POST",
      data: {
        name: userData.name,
        username: userData.username,
        password: userData.password,
        type: userData.type,
        config: userData.config,
        gamData: userData.gamData,
      },
    });
    return { err: res.data.err, msg: res.data.msg };
  } catch (err) {
    return { err: true, msg: err };
  }
};
const addAdunit = async (userData) => {
  try {
    const res = await axios({
      url: "user/create_user",
      method: "POST",
      data: {
        nameM: userData.nameM,
        nameNM: userData.nameNM,
        idM: userData.idM,
        idNM: userData.idNM,
        GA: userData.GA,
        isPlacement: userData.isPlacement,
      },
    });
    return { err: res.data.err, msg: res.data.msg };
  } catch (err) {
    return { err: true, msg: err };
  }
};

const verifyUser = async (userData) => {
  try {
    const res = await axios({
      url: "user/gam_login",
      method: "POST",
      data: {
        username: userData.username,
        password: userData.password,
        userinfo: userData.userinfo,
      },
    });
    return { data: res.data, err: false };
  } catch (err) {
    console.error(err.message);
    return { err: true, data: { msg: "Server Error" } };
  }
};

const fetchGamData = async (data) => {
  try {
    const res = await axios({
      url: "user/gamdata_config",
      method: "POST",
      data: {
        username: data.username,
      },
    });
    return { data: res.data.data, err: false };
  } catch (err) {
    return { err: true, msg: err };
  }
};

const logoutUser = async (token) => {
  try {
    const res = await axios({
      url: "user/logout",
      method: "POST",
      data: {
        token,
      },
    });
    return { data: res.data, err: false };
  } catch (err) {
    return { err: true, msg: err };
  }
};

const authUser = async (key) => {
  try {
    const res = await axios({
      url: "user/auth",
      method: "POST",
      data: {
        token: key,
      },
    });
    return { err: false, msg: res.data };
  } catch (err) {
    return { err: true, msg: err };
  }
};

const destroyToken = async (token) => {
  try {
    const res = await axios({
      url: "user/logout",
      method: "POST",
      headers: {
        Authorization: token,
      },
    });
    return { err: false, msg: res.data };
  } catch (err) {
    return { err: true, msg: err };
  }
};

export {
  createGAMUser,
  verifyUser,
  fetchGamData,
  addAdunit,
  logoutUser,
  authUser,
  destroyToken,
};
