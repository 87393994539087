import { Spin } from "antd";

function LoaderComp() {
  return (
    <>
      <div className="w-screen h-screen flex justify-center items-center ">
        <Spin size="large" />
      </div>
    </>
  );
}

export default LoaderComp;
