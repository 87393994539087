import { configureStore } from "@reduxjs/toolkit";
import configSlice from "./reducers/configSlice";
import gamDataSlice from "./reducers/gamDataSlice";
import ipDataSlice from "./reducers/ipDataSlice";

export const store = configureStore({
  reducer: {
    config: configSlice,
    gamData: gamDataSlice,
    ipData: ipDataSlice,
  },
});
