import {
  Layout,
  Typography,
  Form,
  Input,
  Button,
  Skeleton,
  message,
} from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { verifyUser } from "../API/User";
import { useCookies } from "react-cookie";
import getUserInfo from "../helpers/getUserInfo";

const { Header, Content } = Layout;
const { Text, Title } = Typography;

const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

function Login(props) {
  const [cookies, setCookie] = useCookies(["gam_token"]);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const error = (msg) => {
    messageApi.open({
      type: "error",
      content: msg,
    });
  };

  const onFinish = async (values) => {
    const userinfo = await getUserInfo();
    values = { ...values, userinfo };
    const res = await verifyUser(values);

    if (!res.data?.err) {
      const { name, type, token } = res.data;
      const userData = { name, type };

      // Set the cookie with user data as a base64-encoded JSON string
      setCookie("gam_token", `${token}.${btoa(JSON.stringify(userData))}`);

      // Redirect based on user type
      switch (type) {
        case "GAM":
          navigate("/gam");
          break;
        case "FANDOM":
          navigate("/fandom");
          break;
        case "QUIZSITE":
          navigate("/dashboard");
          break;
        case "ADMIN":
          navigate("/admin");
          break;
        default:
          navigate("/gam"); // Default to AFS dashboard
      }
    } else {
      error(res.data?.msg || "Invalid Credentials");
    }
  };

  useEffect(() => {
    const gam_token = cookies.gam_token;

    if (gam_token) {
      // Extract user data from the cookie
      let userData = {};
      userData = JSON.parse(atob(gam_token.split(".")[3]));

      // Redirect based on user type
      switch (userData?.type) {
        case "GAM":
          navigate("/gam");
          break;
        case "FANDOM":
          navigate("/fandom");
          break;
        case "QUIZSITE":
          navigate("/dashboard");
          break;
        case "ADMIN":
          navigate("/admin");
          break;
        default:
          navigate("/gam"); // Default to AFS dashboard
      }
    }
  }, [cookies, navigate]);

  return cookies.gam_token ? (
    <div className="flex justify-center items-center h-[100vh] w-[75%] ml-auto">
      <Skeleton active />
    </div>
  ) : (
    <>
      {contextHolder}
      <Layout>
        <Header
          style={{
            paddingInline: "30px",
            display: "flex",
            alignItems: "center",
            background: "#1976D2",
            justifyContent: "space-between",
            width: "100%",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.5)",
            height: "10vh",
          }}
        >
          <Text className="text-white text-xl font-normal">Unibots</Text>
        </Header>
        <Content className="h-[90vh]">
          <div className="flex justify-center items-center w-full h-full">
            <Form
              name="basic"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 15,
              }}
              form={form}
              className="md:w-[40vw] lg:w-1/4 w-[70vw]"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                borderRadius: "4px",
                boxShadow: "5px 8px 24px 5px rgba(100, 37, 254, 0.2)",
                background: "white",
                padding: "10px",
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              onFieldsChange={() => {
                // console.log(form.getFieldsValue());
              }}
            >
              <div className="font-medium flex justify-start items-center mb-6 pl-4">
                <Title level={3}>Login</Title>
              </div>
              <div>
                <Form.Item
                  label="User"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please input your username!",
                    },
                  ]}
                >
                  <Input placeholder="Enter username" />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password placeholder="Enter password" />
                </Form.Item>
              </div>
              <div className="flex justify-center w-full">
                <Form.Item>
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="bg-[#1976D2]"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </Content>
      </Layout>
    </>
  );
}

export default Login;
