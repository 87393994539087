import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "",
  type: "",
  plot1: "",
  plot2: "",
  plot3: "",
  tableCols: [],
};

export const configSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      state.name = action.payload.name;
      state.type = action.payload.type;
      state.plot1 = action.payload.plot1;
      state.plot2 = action.payload.plot2;
      state.plot3 = action.payload.plot3;
      state.tableCols = action.payload.tableCols;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateConfig } = configSlice.actions;

export default configSlice.reducer;
