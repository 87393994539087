/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { LogoutOutlined } from "@ant-design/icons";
import axios from "axios";
import {
  Select,
  DatePicker,
  Space,
  Typography,
  Button,
  Tag,
  Table,
} from "antd";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import dayjs from "dayjs";
import {
  AreaChart,
  ResponsiveContainer,
  Area,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import "../index.css";
import LoaderComp from "../Utility/LoaderComp";
import { useCookies } from "react-cookie";
import { destroyToken, fetchGamData } from "../API/User";
import Logo from "../helpers/Logo";

const { RangePicker } = DatePicker;

const createDate = (today) => {
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  return [dd, mm, yyyy];
};

function Fandom(props) {
  const [cookies, , removeCookie] = useCookies(["gam_token"]);
  const [token, setToken] = useState("");
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [searchDate, setSearchDate] = useState(0);

  const navigate = useNavigate();

  const [tag1, setTag1] = useState("");
  const [tag2, setTag2] = useState("");
  const [tag3, setTag3] = useState("");
  const [plot1, setPlot1] = useState([]);
  const [plot2, setPlot2] = useState([]);
  const [plot3, setPlot3] = useState([]);
  const [dateRangeVal, setDateRangeVal] = useState([null, null]);
  const [quickDate, setQuickDate] = useState("");
  const [adOptions, setAdOptions] = useState({});
  const [selectedSite, setSelectedSite] = useState("All Units");
  const [selectAdOptions, setSelectAdOptions] = useState("All Units");
  const [dashConfig, setDashConfig] = useState();
  const [filteredColumns, setFilteredColumns] = useState();
  const [countryList, setCountryList] = useState();

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "DATE",
      width: "15vw",
      fixed: true,
      defaultSortOrder: "descend",
      sorter: (a, b) => a.date > b.date,
      sortDirections: ["ascend", "descend", "ascend"],
      render: (_, record) => {
        return record.name ? record.name : record.date;
      },
    },
    {
      title: "Ad Requests",
      dataIndex: "pv",
      key: "pv",
      render: (_, record) => record.pv,
    },
    {
      title: "Impressions",
      dataIndex: "total_imp",
      key: "imp",
    },
    {
      title: "Net Revenue",
      dataIndex: "total_rev",
      key: "rev",
      render: (_, record) => {
        return isNaN(record.total_rev)
          ? 0
          : "$" + (Number(record.total_rev) * 0.8).toFixed(2);
      },
    },
    {
      title: "Gross Revenue",
      dataIndex: "total_rev",
      key: "rev",
      render: (_, record) => {
        return isNaN(record.total_rev)
          ? 0
          : "$" + Number(record.total_rev).toFixed(2);
      },
    },
    {
      title: "Average ECPM  ",
      dataIndex: "avg_ecpm",
      key: "ecpm",
      render: (_, record) => {
        return isNaN(record.avg_ecpm)
          ? "NA"
          : "$" + Number(record.avg_ecpm).toFixed(2);
      },
    },
    {
      title: "Fill Rate",
      dataIndex: "fillrate",
      key: "fillrate",
      render: (_, record) => {
        return !record.pv || isNaN(record.pv)
          ? ""
          : ((Number(record.total_imp) * 100) / Number(record.pv)).toFixed(2) +
              "%";
      },
    },
    {
      title: "Viewability",
      dataIndex: "viewability",
      key: "viewability",
      render: (_, record) => {
        return !record.viewability ? "" : +record.viewability.toFixed(2);
      },
    },
  ];

  const plotMappings = {
    imp: {
      graph: "impGraph",
      label: "Impressions",
      tag: "Total Imp",
    },
    rev: {
      graph: "revGraph",
      label: "Revenue",
      tag: "Total Rev",
    },
    rpm: {
      graph: "rpmGraph",
      label: "RPM",
      tag: "Average RPM",
    },
    ecpm: {
      graph: "ecpmGraph",
      label: "eCPM",
      tag: "Average eCPM",
    },
  };

  function createAdoptions(arr) {
    let result = {};

    arr.forEach((obj) => {
      const label = obj.label;

      if (!result[label]) {
        result[label] = {};
      }

      Object.keys(obj).forEach((key) => {
        if (key === "label") return;

        if (!result[label][key]) {
          result[label][key] = [];
        }

        if (Array.isArray(obj[key])) {
          result[label][key].push(...obj[key]);
        } else {
          result[label][key] = obj[key];
        }
      });
    });

    const allUnits = {};
    Object.keys(result).forEach((label) => {
      Object.keys(result[label]).forEach((key) => {
        if (!allUnits[key]) {
          allUnits[key] = [];
        }
        allUnits[key].push(result[label][key]);
      });
    });
    result["All Units"] = allUnits;

    return result;
  }

  function createSelectAdOptions(arr) {
    let result = [];

    result.push({ label: "All Units", value: "All Units" });

    arr.forEach((obj) => {
      result.push({ label: obj.label, value: obj.label });
    });

    return result;
  }

  function filterColumns(cols) {
    const newCols = cols.filter((col) =>
      dashConfig.tableCols?.includes(col.dataIndex)
    );
    return newCols;
  }

  async function getGamData(name) {
    const res = await fetchGamData({ username: name });
    setDashConfig(res.data.config);
    setCountryList(res.data.config?.countryList);

    setAdOptions(createAdoptions(res.data.gamData));
    setSelectAdOptions(createSelectAdOptions(res.data.gamData));
  }

  const handleQuickDateChange = (days) => {
    const today = new Date();
    const d1 = createDate(today);

    const startDate = new Date(today);
    startDate.setDate(today.getDate() - (days - 1));
    const d2 = createDate(startDate);

    const date1 = d2[2] + "-" + d2[1] + "-" + d2[0];
    const date2 = d1[2] + "-" + d1[1] + "-" + d1[0];

    setDateRangeVal([date1, date2]);
    setSearchDate(!searchDate);
    setQuickDate(`${days}D`);
  };

  const flattenData = (data) => {
    const result = [];
    const flatten = (record, parentKey) => {
      const { key, children, ...rest } = record;
      const currentKey = parentKey ? `${parentKey}.${key}` : key;

      const formattedData = Object.entries(rest).reduce(
        (acc, [property, value]) => {
          // Check if the value is a number and round it to 2 decimal places
          if (
            isNaN(value) &&
            property !== "date" &&
            property !== "name" &&
            property !== "website"
          ) {
            acc[property] = 0;
          } else if (typeof value === "number") {
            acc[property] = value.toFixed(2);
          } else {
            acc[property] = value;
          }
          return acc;
        },
        {}
      );

      if (selectedSite === "All Units")
        result.push({
          key: currentKey,
          Date: formattedData.date,
          Domain: formattedData.name,
          "Ad Requests": formattedData.pv,
          "Total Impressions": formattedData.total_imp,
          "Net Rev": 0.7 * formattedData.total_rev,
          "Total Gross Rev": formattedData.total_rev,
          "Avg eCPM": formattedData.avg_ecpm,
          "Fill Rate": formattedData.pv
            ? (
                (Number(formattedData.total_imp) * 100) /
                Number(formattedData.pv)
              ).toFixed(2)
            : "",
          Viewability: formattedData.viewability,
        });
      else
        result.push({
          key: currentKey,
          Date: formattedData.date,
          Country: formattedData.name,
          "Ad Requests": formattedData.pv,
          "Total Impressions": formattedData.total_imp,
          "Net Rev": 0.7 * formattedData.total_rev,
          "Total Gross Rev": formattedData.total_rev,
          "Avg eCPM": formattedData.avg_ecpm,
          "Fill Rate": formattedData.pv
            ? (
                (Number(formattedData.total_imp) * 100) /
                Number(formattedData.pv)
              ).toFixed(2)
            : "",
          Viewability: formattedData.viewability,
        });

      if (children) {
        children.forEach((child) => flatten(child, currentKey));
      }
    };
    data.forEach((record) => flatten(record));
    return result;
  };

  const flattenedData = flattenData(tableData).map((record) => {
    delete record.key;
    delete record.rpm;
    return record;
  });

  const handleLogout = () => {
    removeCookie("gam_token");
    destroyToken(token);
    navigate("/login");
  };

  useEffect(() => {
    const gam_token = cookies.gam_token;
    let userData = {};

    if (gam_token) {
      userData = JSON.parse(atob(gam_token.split(".")[3]));
      setToken(gam_token.split(".").slice(0, 3).join("."));

      // setUserName(userData?.username);

      getGamData(userData?.name);
    }
  }, [cookies]);

  useEffect(() => {
    var today = new Date();
    var firstDay = new Date(today.getFullYear(), today.getMonth(), 1);

    var d = createDate(today);
    const d1 = d[2] + "-" + d[1] + "-" + d[0];
    d = createDate(firstDay);
    const d2 = d[2] + "-" + d[1] + "-" + d[0];
    setDateRangeVal([d2, d1]);

    setSearchDate(!searchDate);
  }, []);

  useEffect(() => {
    if (
      dateRangeVal[0] != null &&
      dateRangeVal[1] != null &&
      searchDate !== 0
    ) {
      const getData = async () => {
        setLoadingScreen(true);
        setTableData([]);

        const res = await axios.post(
          `${process.env.REACT_APP_CLIENTDASH_URL}/client/get_countryListDataInTableRow`,
          {
            dateStart: dateRangeVal[0],
            dateEnd: dateRangeVal[1],
            adUnitIDMain: adOptions[selectedSite]["idMain"],
            adUnitIDNewMain: adOptions[selectedSite]["idNewMain"],
            GaPropertyId: adOptions[selectedSite]["GaPropertyId"],
            countryList: countryList[selectedSite],
            // userinfo,
          },
          {
            headers: {
              // "x-api-key": process.env.REACT_APP_API_KEY,
              Authorization: token,
            },
          }
        );

        setTableData(res.data.data);

        setTag1(res.data[dashConfig.plot1]);
        setTag2(res.data[dashConfig.plot2]);
        setTag3(res.data[dashConfig.plot3]);

        setPlot1(res.data[plotMappings[dashConfig.plot1].graph]);
        setPlot2(res.data[plotMappings[dashConfig.plot2].graph]);
        setPlot3(res.data[plotMappings[dashConfig.plot3].graph]);

        setFilteredColumns(filterColumns(columns));

        setLoadingScreen(false);
      };
      if (Object.keys(adOptions).length > 0) {
        getData();
      }
    }
  }, [searchDate, adOptions, selectedSite]);

  return (
    <>
      {loadingScreen ? (
        <LoaderComp />
      ) : (
        <div className="w-full">
          <div className="sm:px-2 md:px-4 lg:px-6">
            <div
              className={`grid gap-1 grid-cols-1 lg:grid-cols-5 justify-between items-center my-4`}
            >
              <div className="flex justify-center">
                <Logo />
              </div>

              <div className="flex justify-center lg:col-span-2">
                <div className="flex items-center my-3">
                  <Space direction="vertical" size={12}>
                    <RangePicker
                      value={
                        dateRangeVal[0] !== "" && dateRangeVal[1] !== ""
                          ? [dayjs(dateRangeVal[0]), dayjs(dateRangeVal[1])]
                          : undefined
                      }
                      onChange={(date, dateString) => {
                        setDateRangeVal(dateString);
                      }}
                    />
                  </Space>
                  <Button
                    style={{ marginLeft: 4 }}
                    type="primary"
                    onClick={() => setSearchDate(searchDate + 1)}
                  >
                    Search
                  </Button>
                </div>
              </div>

              <div className="flex justify-center">
                <Button
                  onClick={() => handleQuickDateChange(1)}
                  type={quickDate === "1D" ? "primary" : "default"}
                >
                  24H
                </Button>

                <Button
                  onClick={() => handleQuickDateChange(7)}
                  type={quickDate === "7D" ? "primary" : "default"}
                >
                  7D
                </Button>

                <Button
                  onClick={() => handleQuickDateChange(30)}
                  type={quickDate === "30D" ? "primary" : "default"}
                >
                  30D
                </Button>
              </div>

              <div className="flex justify-center">
                <Button
                  danger
                  type="text"
                  size="large"
                  icon={<LogoutOutlined />}
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              </div>
            </div>
            <div>
              <div>
                <div
                  className={`grid grid-cols-1 lg:grid-cols-4 justify-between items-center my-4 h-auto lg:h-72`}
                >
                  <div>
                    <div className="flex align-top justify-center pb-4">
                      <Select
                        showSearch
                        style={{
                          width: "80%",
                          maxWidth: 420,
                        }}
                        placeholder="Select Adunit"
                        options={selectAdOptions}
                        value={selectedSite}
                        onChange={(val) => {
                          setSelectedSite(val);
                        }}
                      ></Select>
                    </div>
                  </div>

                  <div className="block h-64 lg:h-full w-full">
                    <div className="flex justify-center h-5/6 w-full">
                      <ResponsiveContainer width="100%" height="100%">
                        <AreaChart
                          data={plot1}
                          syncId="syncedTooltip"
                          margin={{ top: 10, bottom: 10, right: 20 }}
                        >
                          <defs>
                            <linearGradient
                              id="colorImp"
                              x1="0"
                              y1="0"
                              x2="0"
                              y2="1"
                            >
                              <stop
                                offset="5%"
                                stopColor="#2e7d32"
                                stopOpacity={0.4}
                              />
                              <stop
                                offset="95%"
                                stopColor="#2e7d32"
                                stopOpacity={0}
                              />
                            </linearGradient>
                          </defs>
                          <XAxis dataKey="name" />
                          <YAxis />
                          <Tooltip />
                          {/* <Legend margin={{ right: 1 }} /> */}
                          <Area
                            type="monotone"
                            dataKey={plotMappings[dashConfig?.plot1]?.label}
                            stroke="#2e7d32"
                            fillOpacity={1}
                            fill="url(#colorImp)"
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </div>
                    <div className="flex justify-center">
                      <Tag
                        color="green"
                        style={{
                          padding: "6px",
                          fontSize: "13px",
                          borderRadius: "8px",
                        }}
                      >
                        {`Total Imp: ${tag1}`}
                      </Tag>
                    </div>
                  </div>

                  <div className="block h-64 lg:h-full w-full">
                    <div className="flex justify-center h-5/6 w-full">
                      <ResponsiveContainer width="100%" height="100%">
                        <AreaChart
                          data={plot2}
                          syncId="syncedTooltip"
                          margin={{ top: 10, bottom: 10, right: 20 }}
                        >
                          <defs>
                            <linearGradient
                              id="colorRev"
                              x1="0"
                              y1="0"
                              x2="0"
                              y2="1"
                            >
                              <stop
                                offset="5%"
                                stopColor="#1976d2"
                                stopOpacity={0.4}
                              />
                              <stop
                                offset="95%"
                                stopColor="#1976d2"
                                stopOpacity={0}
                              />
                            </linearGradient>
                          </defs>

                          <XAxis
                            dataKey="name"
                            style={{ marginLeft: "50px" }}
                          />
                          <YAxis />
                          <Tooltip />
                          {/* <Legend /> */}
                          <Area
                            type="monotone"
                            dataKey={plotMappings[dashConfig?.plot2]?.label}
                            stroke="#1976d2"
                            fillOpacity={1}
                            fill="url(#colorRev)"
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </div>
                    <div className="flex justify-center">
                      <Tag
                        color="blue"
                        style={{
                          padding: "6px",
                          fontSize: "13px",
                          borderRadius: "8px",
                        }}
                      >
                        {`Total Rev: $${tag2}`}
                      </Tag>
                    </div>
                  </div>

                  <div className="block h-64 lg:h-full w-full">
                    <div className="flex justify-center h-5/6 w-full">
                      <ResponsiveContainer width="100%" height="100%">
                        <AreaChart
                          data={plot3}
                          syncId="syncedTooltip"
                          margin={{ top: 10, bottom: 10, right: 20 }}
                        >
                          <defs>
                            <linearGradient
                              id="colorRpm"
                              x1="0"
                              y1="0"
                              x2="0"
                              y2="1"
                            >
                              <stop
                                offset="5%"
                                stopColor="#e83462"
                                stopOpacity={0.4}
                              />
                              <stop
                                offset="95%"
                                stopColor="#e83462"
                                stopOpacity={0}
                              />
                            </linearGradient>
                          </defs>
                          <XAxis dataKey="name" />
                          <YAxis />
                          <Tooltip />
                          <Area
                            type="monotone"
                            dataKey={plotMappings[dashConfig?.plot3]?.label}
                            stroke="#e83462"
                            fillOpacity={1}
                            fill="url(#colorRpm)"
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </div>
                    <div className="flex justify-center">
                      <Tag
                        color="error"
                        style={{
                          padding: "6px",
                          fontSize: "13px",
                          borderRadius: "8px",
                        }}
                      >
                        {`${plotMappings[dashConfig?.plot3]?.tag}: $${tag3}`}
                      </Tag>
                    </div>
                  </div>
                </div>

                <div className={`flex flex-row justify-end mb-2 px-2`}>
                  <div className="flex justify-end my-2 lg:my-0">
                    <Button
                      type="primary"
                      size="small"
                      style={{
                        backgroundColor: "#1B5E20",
                        borderColor: "#1B5E20",
                      }}
                    >
                      <CSVLink
                        filename={"DataTable.csv"}
                        data={flattenedData}
                        className="btn btn-primary"
                        style={{ textDecoration: "none" }} // Remove underline from text
                      >
                        <Typography variant="h5" sx={{ color: "#FFFFFF" }}>
                          <span style={{ color: "#FFFFFF" }}>
                            Export to CSV
                          </span>
                        </Typography>
                      </CSVLink>
                    </Button>
                  </div>
                </div>
                <div className="flex">
                  <Table
                    bordered
                    columns={filteredColumns}
                    pagination={{
                      defaultPageSize: 10,
                      showSizeChanger: true,
                      pageSizeOptions: ["10", "20", "50", "100"],
                    }}
                    dataSource={tableData}
                    scroll={{
                      x: 1000,
                      y: 800,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Fandom;
